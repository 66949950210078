<template>
  <div class="hello my-5">

  <img src="@/assets/technomage-logo.png" class="img-fluid" alt="Technomage logo" style="max-height: 300px">
  
  <h1 class="brand" style="">Technomage </h1>
  <p class="lead">Riding the hype waves with AI, blockchain, NFTs and more.</p>



    <figure class="my-5">
  <blockquote class="blockquote">
    <p class="fw-lighter" style="color: #999;">"We are dreamers, shapers, singers, and makers. We study the mysteries of laser and circuit, crystal and scanner, holographic demons and invocation of equations. These are the tools we employ, and we know many things."</p>
  </blockquote>
  <figcaption class="blockquote-footer">
    Elric
  </figcaption>
</figure>




    <figure>
  <blockquote class="blockquote">
    <p class="fw-lighter" style="color: #999;">"Any sufficiently advanced technology is indistinguishable from magic."</p>
  </blockquote>
  <figcaption class="blockquote-footer">
    Clarke's Third Law
  </figcaption>
</figure>




  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

